.o-slide {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 0;
}

.o-slide__inner {
  box-sizing: border-box;
  position: absolute; //This was previously set at relative
  top: 50%;
  left: 50%;
  @include transform(translate(-50%,-50%));
  width: 100%; //This is overwritten by data attributes
  height: 100%;   //This is overwritten by data attributes
}

.o-slide__image,
.o-slide__video {
  object-fit: contain;
  width: 100%;
  height: 100%;
  font-family: 'object-fit: contain;';

  &[data-objectfit="contain"] {
    object-fit: contain !important;
    font-family: 'object-fit: contain;';
  }

  &[data-objectfit="cover"] {
    object-fit: cover !important;
    font-family: 'object-fit: cover;';
  }

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

}

//Sizing (Padding)
[data-size="full"] {
  padding: 0;
  //width: 100%;
  //height: 100%;
}

[data-size="large"] {
  padding: $padding*2.5;
  &[data-leftright="left"] {
    padding-right: calc(#{$padding*2.5}/2);
  }
  &[data-leftright="right"] {
    padding-left: calc(#{$padding*2.5}/2);
  }
  @include mq($br-small) {
    padding: $padding*2;
    &[data-width="half"] {
      padding: $padding*1;
    }
    &[data-width="half"][data-leftright="left"] {
      padding-right: calc(#{$padding*1}/2);
    }
    &[data-width="half"][data-leftright="right"] {
      padding-left: calc(#{$padding*1}/2);
    }
    &[data-leftright="left"] {
      padding-right: calc(#{$padding*2}/2);
    }
    &[data-leftright="right"] {
      padding-left: calc(#{$padding*2}/2);
    }
  }
}

[data-size="medium"] {
  padding: $padding*6;
  &[data-leftright="left"] {
    padding-right: calc(#{$padding*6}/2);
  }
  &[data-leftright="right"] {
    padding-left: calc(#{$padding*6}/2);
  }
  @include mq($br-medium) {
    padding: $padding*4;
    &[data-width="half"] {
      padding: $padding*2.66;
    }
    &[data-width="half"][data-leftright="left"] {
      padding-right: calc(#{$padding*2.66}/2);
    }
    &[data-width="half"][data-leftright="right"] {
      padding-left: calc(#{$padding*2.66}/2);
    }
    &[data-leftright="left"] {
      padding-right: calc(#{$padding*4}/2);
    }
    &[data-leftright="right"] {
      padding-left: calc(#{$padding*4}/2);
    }
  }
  @include mq($br-med-small) {
    padding: $padding*3.5;
    &[data-width="half"] {
      padding: $padding*2.33;
    }
    &[data-width="half"][data-leftright="left"] {
      padding-right: calc(#{$padding*2.33}/2);
    }
    &[data-width="half"][data-leftright="right"] {
      padding-left: calc(#{$padding*2.33}/2);
    }
    &[data-leftright="left"] {
      padding-right: calc(#{$padding*3.5}/2);
    }
    &[data-leftright="right"] {
      padding-left: calc(#{$padding*3.5}/2);
    }
  }
  @include mq($br-small) {
    padding: $padding*3;
    &[data-width="half"] {
      padding: $padding*1.5;
    }
    &[data-width="half"][data-leftright="left"] {
      padding-right: calc(#{$padding*1.5}/2);
    }
    &[data-width="half"][data-leftright="right"] {
      padding-left: calc(#{$padding*1.5}/2);
    }
    &[data-leftright="left"] {
      padding-right: calc(#{$padding*3}/2);
    }
    &[data-leftright="right"] {
      padding-left: calc(#{$padding*3}/2);
    }
  }
}

[data-size="small"] {
  padding: $padding*9;
  &[data-leftright="left"] {
    padding-right: calc(#{$padding*9}/2);
  }
  &[data-leftright="right"] {
    padding-left: calc(#{$padding*9}/2);
  }
  @include mq($br-medium) {
    padding: $padding*6;
    &[data-width="half"] {
      padding: $padding*4;
    }
    &[data-width="half"][data-leftright="left"] {
      padding-right: calc(#{$padding*4}/2);
    }
    &[data-width="half"][data-leftright="right"] {
      padding-left: calc(#{$padding*4}/2);
    }
    &[data-leftright="left"] {
      padding-right: calc(#{$padding*6}/2);
    }
    &[data-leftright="right"] {
      padding-left: calc(#{$padding*6}/2);
    }
  }
  @include mq($br-med-small) {
    padding: $padding*5;
    &[data-width="half"] {
      padding: $padding*3.33;
    }
    &[data-width="half"][data-leftright="left"] {
      padding-right: calc(#{$padding*3.33}/2);
    }
    &[data-width="half"][data-leftright="right"] {
      padding-left: calc(#{$padding*3.33}/2);
    }
    &[data-leftright="left"] {
      padding-right: calc(#{$padding*5}/2);
    }
    &[data-leftright="right"] {
      padding-left: calc(#{$padding*5}/2);
    }
  }
  @include mq($br-small) {
    padding: $padding*4;
    &[data-width="half"] {
      padding: $padding*2;
    }
    &[data-width="half"][data-leftright="left"] {
      padding-right: calc(#{$padding*2}/2);
    }
    &[data-width="half"][data-leftright="right"] {
      padding-left: calc(#{$padding*2}/2);
    }
    &[data-leftright="left"] {
      padding-right: calc(#{$padding*4}/2);
    }
    &[data-leftright="right"] {
      padding-left: calc(#{$padding*4}/2);
    }
  }
}

//Width of container
[data-width="full"] {
  width: 100% !important;
}

[data-width="half"] {
  width: 50% !important;
}

//Position of 'inner' container
[data-leftright="left"] {
  left:0;
  @include transform(translate(0,-50%));
}

[data-leftright="right"] {
  right: 0;
  @include transform(translate(0,-50%));
}

//Position of image within container
[data-position="lefthalf_left"],
[data-position="righthalf_left"] {
  object-position: 0 50%;
  &[data-objectfit="contain"] {
    font-family: 'object-fit: contain; object-position: 0 50%;';
  }

  &[data-objectfit="cover"] {
    object-fit: cover !important;
    font-family: 'object-fit: cover; object-position: 0 50%;';
  }
}

[data-position="lefthalf_right"],
[data-position="righthalf_right"] {
  object-position: 100% 50%;
  &[data-objectfit="contain"] {
    font-family: 'object-fit: contain; object-position: 100% 50%;';
  }

  &[data-objectfit="cover"] {
    object-fit: cover !important;
    font-family: 'object-fit: cover; object-position: 100% 50%;';
  }
}

/*
lefthalf_left: Left
lefthalf_center: Centered within left column
lefthalf_right: Right within left column
center: Center
righthalf_left: Left within right column
righthalf_center: Centered within right column
righthalf_right: Right
*/
