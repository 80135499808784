//Container
//Acts like a wrapper/inner div

.o-section {
	@include section();
}

.o-section--padding {
	@include section--padding();
}

.o-section--padding--less {
	@include section--padding--less();
}

.o-section--padding--more {
	@include section--padding--more();
}
