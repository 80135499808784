// Typography
// Typographical base selectors. Define base styles on single element selectors here.

body {
  @include sans();
  color: $fakeblack;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "case" 1, "ss03" 1;
  -moz-font-feature-settings: "case" 1, "ss03" 1;
  -ms-font-feature-settings: "case" 1, "ss03" 1;
  -o-font-feature-settings: "case" 1, "ss03" 1;
  -webkit-font-feature-settings: "case" 1, "ss03" 1;
  font-feature-settings: "case" 1, "ss03" 1;
  font-variant-ligatures: common-ligatures;
}

.tnum {
  -moz-font-feature-settings: "tnum" 1;
  -webkit-font-feature-settings: "tnum" 1;
	font-feature-settings: "tnum" 1;
}

a {
  color: $fakeblack;
  text-decoration: none;
  @include transition(color, $speed, $ease);
  &:hover {
    color: $textgray;
    text-decoration: none;
  }
}

p {
  margin-bottom: $baseline;
  max-width: $max_text_width;
  &:last-child {
    margin-bottom: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
  font-size: inherit;
}

sup {
  top: -0.75em;
  font-size: 50%;
}

b, strong {
  font-weight: normal;
  text-transform: uppercase;
  display:inline-block;
}

.type--display {
  @include display();
}

.type--large {
  @include sans--large();
}

.type--uppercase {
  text-transform: uppercase;
}

.type--tag:after {
  content: ", ";
}
.type--tag:last-child:after {
  content: "";
}
