// Fonts

@font-face {
    font-family: "Unica77LLWebRegular";
    src: url("../fonts/Unica77LLWeb-Regular.eot");
    src: url("../fonts/Unica77LLWeb-Regular.eot?#iefix") format("embedded-opentype"),
         url("../fonts/Unica77LLWeb-Regular.woff2") format("woff2"),
         url("../fonts/Unica77LLWeb-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
