// Layout

// Layout variables

$max_container_width: 100%;
$max_text_width: 39em;

$baseline: 1.33em;
$padding: 1.33em;

// Breakpoints
$br-xlarge:           1800px;
$br-large:            1440px;
$br-med-large:        1280px;
$br-medium:           1024px;
$br-med-small:        768px;
$br-small:            520px;

// Grid

@mixin container() {
  @include parent-behaviour;
  width: 100%;
  max-width: $max_container_width;
  margin-left: auto;
  margin-right: auto;
}

@mixin container-item() {
  padding-left: $padding;
  padding-right: $padding;
}

@mixin section() {
  @include parent-behaviour;
}

@mixin section--padding() {
  @include parent-behaviour;
  padding-top: $padding/2;
  padding-bottom: $padding/2;
}

@mixin section--padding--less() {
  @include parent-behaviour;
  padding-top: $padding/4;
  padding-bottom: $padding/4;
}

@mixin section--padding--more() {
  @include parent-behaviour;
  padding-top: $padding*2;
  padding-bottom: $padding*2;
}

@mixin col--padding-bottom() {
  @include parent-behaviour;
  padding-bottom: $padding*2;
}

@mixin row($inside: false) {
  @if($inside == true) {
    margin-left: -$padding/2.75;
    margin-right: -$padding/2.75;
  }
  @else {
    @include container();
    padding-left: $padding/2.75;
    padding-right: $padding/2.75;
  }
  &:before, &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin row-no-padding() {
  @include container();
  padding-left: 0;
  padding-right: 0;

  &:before, &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin col($width: 1, $paddingBool: true, $centered: false) {
  @if($centered == true) {
    margin-left: auto;
    margin-right: auto;
    display:block;
  }
  @else {
    float: left;
  }
  width: percentage($width);
  @if($paddingBool == true) {
    padding-left: $padding/2.75;
    padding-right: $padding/2.75;
  }
}

@mixin push($width: 0) {
  //position: relative;
  margin-left: percentage($width);
}
@mixin pull($width: 0) {
  //position: relative;
  margin-right: percentage($width);
}
