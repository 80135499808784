//General

html {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
			box-sizing: border-box;
}

html, body {
	width: 100%;
	height: 100%;
	overflow:hidden;
	background-color: $fakewhite;
}

*, *::before, *::after {
	box-sizing: inherit;
}

body.no-scroll {
	overflow: hidden;
}


body.page-is-inactive {
	.c-header {
		opacity: 0;
	}
}

.barba-container {
	position: relative;
}

body.page-transition-helper {
	.page-wrapper {
		@include transform(translate3d(0,0,0));
		@include transition(transform, $normal, $ease);
	}
}

body.page-is-pushed {
	.page-wrapper {
		@include transform(translate3d(0,27.5vw,0));
	}
}
