// Font settings
$sans: 'Unica77LLWebRegular', 'Helvetica Neue', Helvetica, Arial, "Lucida Grande", sans-serif;
$normal: 400;

// Typography
@mixin sans() {
	text-transform: uppercase;
	font-family: $sans;
	font-size: 12px;
	letter-spacing: 0.045em;
	line-height: $baseline;

	@include mq($br-small) {
		font-size: 11px;
	}
}

@mixin sans--large() {
	font-family: $sans;
	font-size: $baseline*2;
	line-height: $baseline;
}

@mixin display() {
	font-family: $sans;
	font-size: 3.47vw;
	line-height: 1.1em;
	text-transform: none;

	@include mq($br-large) {
		font-size: 50px;
		line-height: 1.1em;
	}

	@include mq($br-med-large) {
		font-size: 45px;
		line-height: 1.1em;
	}

	@include mq($br-medium) {
		font-size: 40px;
		line-height: 1.1em;
	}

	@include mq($br-med-small) {
		font-size: 35px;
		line-height: 1.1em;
	}

	@include mq($br-small) {
		font-size: 30px;
		line-height: 1.1em;
	}

}
