.c-overview {
  text-align: center;
  width: 100%;
}

.o-thumbnail {
  display: inline-block;
  padding: $padding*2;
  box-sizing: initial;
  transform: tranlateZ(0);
  vertical-align: middle;
}

.o-thumbnail-link {
  @include transition(opacity, $speed, $ease);
  opacity: 1;
  &.is-active {
    opacity: 0.5;
  }
}
