.c-header {
	z-index: $z-header;
	position:fixed;
	top:0;
	left:0;
	right:0;
	display:block;
	height: auto;
  padding: $padding/2;
	opacity: 1;
	@include transition(opacity, $speed, $ease);
	//mix-blend-mode: difference;
	&.is-hidden{
	 opacity: 0;
	}

}

body.overlay-page-is-open .c-header {
	.rsGCaption {
		pointer-events: none;
		opacity: 0;
	}
	.nav__current-page {
		opacity: 1;
	}
	&:hover {
		.nav__current-page {
			opacity: 0;
		}
	}
}

.c-header .nav__home {
	position: relative;
	display: inline-block;
	float: left;
}

.c-header__content {
	position: relative;
	display:inline-block;
	float: left;
	opacity: 0;
	@include transition(opacity, $speed, $ease);
	&.is-active {
		opacity: 1;
	}
}

.c-header .rsGCaption,
.c-header .nav__current-page {
	pointer-events: auto;
	position: absolute;
	left: 0;
	width: 100vw;
}

.c-header .nav__current-page {
	opacity: 0;
	pointer-events:none;
}

.c-header nav,
.c-header .rsGCaption,
.c-header .nav__current-page{
	@include transition(opacity, $speed, $ease);
}

.c-header .nav__home,
.c-header nav ul li,
.c-header .rsGCaption .rsCaption,
.c-header .nav__current-page span{
	position: relative;
  display: inline-block;
  float: left;
	//mix-blend-mode: difference;
  padding: $padding/4 $padding/2.75;
  @include transition(opacity, $speed, $ease);
}

.c-header nav{
	pointer-events: none;
	opacity: 0;
}

.c-header:hover,
.c-header:focus {
  nav {
		pointer-events: auto;
    opacity: 1;
  }
	.rsGCaption {
		pointer-events: none;
		opacity: 0;
	}
}

.c-header__btn-sound-container {
	position: absolute;
	right: 0;
  padding: 0 $padding/2;
}

.c-header__btn-sound {
	cursor: pointer;
  display: inline-block;
  float: right;
  padding: $padding/4 $padding/2.75;
  @include transition(opacity, $speed, $ease);
	pointer-events: none;
	opacity: 0 !important;

	&.is-visible {
		pointer-events: auto;
		opacity: 1 !important;
	}
}
