//Container
//Acts like a wrapper/inner div

.o-container {
	@include container();
	padding-top: $padding/2;
	padding-bottom: $padding/2;
}

.o-container--page {
	@include container();
}

.o-container-item {
	@include container-item();
}
